import Vue from 'vue';

import firebase from '@/services/firebase';
import Button from '@/components/Admin/Button/Button';
import './SetPassword.css';

const SetPassword = Vue.extend({
  data() {
    return {
      actionInProgress: false,
      formData: {
        confirmPassword: '',
        password: '',
      },
      formError: '',
    };
  },

  methods: {
    async handleChangePassword(event: Event) {
      event.preventDefault();

      this.formError = '';

      const { password, confirmPassword } = this.formData;
      if (password !== confirmPassword) {
        this.formError = 'Passwords do not match, please confirm';
        return;
      }

      this.actionInProgress = true;

      const actionCode = this.$route.query.oobCode as string;

      const actionCodeErrMsg = 'This invite link has expired. Kindly contact us to re-generate a new code';

      if (!actionCode) {
        this.formError = actionCodeErrMsg;

        return;
      }

      const email = await firebase.auth().verifyPasswordResetCode(actionCode)
        .catch((err) => {
          console.log('Error verifying action code => ', err);
          return null;
        })
        .finally(() => {
          this.actionInProgress = false;
        });

      if (!email) {
        this.formError = actionCodeErrMsg;
        return;
      }

      const passwordChangeSuccessful = await firebase.auth()
        .confirmPasswordReset(actionCode, password)
        .then(() => true)
        .catch((err) => {
          console.log('Error confirming password reset => ', err);
        });

      if (!passwordChangeSuccessful) {
        this.formError = actionCodeErrMsg;
        return;
      }

      firebase.auth().signInWithEmailAndPassword(email, password)
        .then(() => {
          this.$router.push('/client/albums');
        })
        .catch((err) => {
          console.log('Error signing in user after password change => ', err);
        })
        .finally(() => {
          this.actionInProgress = false;
        });
    },
  },

  render() {
    return (
      <div class="set-password">

        <form onSubmit={this.handleChangePassword}>
          <header class="dashboard-header">
            <img src='https://res.cloudinary.com/nerdge/image/upload/q_auto,f_auto,fl_lossy,dpr_auto/v1582663321/Teefal%20Studios/logo.png' />
            <h1 class="dashboard-header__title">Set Password</h1>
            <strong class="dashboard-header__subtitle">Set a strong password to view your pictures</strong>
          </header>

          <div class="form-group">
            <label for="password">Password</label>
            <input type="password" name="password" v-model={this.formData.password} id="password" required />
          </div>

          <div class="form-group">
            <label for="password">Confirm Password</label>
            <input type="password" name="confirmPassword" v-model={this.formData.confirmPassword} id="confirmPassword" required />
          </div>

          {
            this.formError && (
              <div class="form-group">
                <div class="form-error">{this.formError}</div>
              </div>
            )
          }

          <div class="form-group">
            <Button loading={this.actionInProgress} primary type='submit'>Set Password</Button>
          </div>
        </form>
      </div >
    );
  },
});

export default SetPassword;
